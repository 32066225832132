export default {
    methods: {
        /**
         * GET order detail by order ID
         * @param {string} orderId
         */
        async axiosGetOrderDetail(orderId) {
            return this.$axios.get(this.$config.API_URL_V1 + `order/${orderId}`, this.$config.HEADERS)
        },
    }
}